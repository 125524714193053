import React, { useRef, useEffect } from 'react'
import loop from 'usfl/loop'
import Boid from 'boid'
import randomChoice from 'usfl/array/random-choice'
import random from 'usfl/math/random'
import randomSign from 'usfl/math/random-sign'
import lerp from 'usfl/math/lerp'
import pointerCoords from 'usfl/input/pointer-coords'
import Graphics from 'usfl/graphics'

function createBoid (options, settings, rect, images) {
  const boid = new Boid(options)
  boid.position.x = rect.width * Math.random()
  boid.position.y = rect.height * Math.random()
  boid.velocity.x = random(settings.minVelocity, settings.maxVelocity) * randomSign()
  boid.velocity.y = random(settings.minVelocity, settings.maxVelocity) * randomSign()
  boid.userData.scale = random(settings.minScale, settings.maxScale)
  boid.radius = settings.radius * boid.userData.scale
  boid.userData.radiusSq = boid.radius * boid.radius
  boid.userData.rotation = 0
  boid.userData.rotationSpeed = random(0.01, 0.02) * randomSign()
  boid.userData.colliding = false
  const img = document.createElement('img')
  img.src = randomChoice(images)
  boid.userData.img = img

  return boid
}

export default function Particles ({ container, bgColor, count, radius, transparency, images }) {
  const canvas = useRef()

  let gfx
  let coords
  let target
  let boids
  let settings
  let rect = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }

  function initialize (canvas, bgColor, count, radius, transparency, images) {
    settings = {
      bgColor,
      count,
      minVelocity: 1,
      maxVelocity: 3,
      arriveMaxDistance: 150,
      minScale: 0.15,
      maxScale: 0.45,
      radius: radius,
      maxSpeed: 0.4
    }

    gfx = new Graphics(canvas)
    gfx.size(rect.width, rect.height)
    gfx.clear(settings.bgColor)

    const options = {
      bounds: {
        x: 0,
        y: 0,
        width: rect.width,
        height: rect.height
      },
      edgeBehavior: 'wrap',
      radius: settings.radius,
      mass: 1.0,
      maxSpeed: settings.maxSpeed,
      maxForce: 0.2,
      arriveThreshold: 12,
      wanderDistance: 20,
      wanderRadius: 1,
      wanderAngle: 0,
      wanderRange: 1,
      avoidDistance: 300,
      avoidBuffer: 20,
      pathThreshold: 20,
      maxDistance: 300,
      minDistance: 60
    }

    boids = []
    while (boids.length < settings.count - 1) {
      boids.push(createBoid(options, settings, rect, images))
    }

    coords = pointerCoords()
    target = Boid.vec2(0, 0)
  }

  function onLoop (dt) {
    gfx.clear(bgColor)

    target.x = coords.x - rect.x
    target.y = coords.y - rect.y

    for (let i = 0; i < boids.length; i++) {
      const boid = boids[i]
      boid.userData.colliding = false

      for (let j = 0; j < boids.length; j++) {
        const boidB = boids[j]
        if (boidB !== boid && boidB.position.distanceSq(boid.position) < boid.userData.radiusSq + boidB.userData.radiusSq) {
          boidB.maxSpeed = boid.maxSpeed = settings.maxSpeed * 3
          boidB.flee(boid.position)
          boid.flee(boidB.position)
          boid.userData.colliding = true
          boidB.userData.colliding = true
        }
      }

      if (!boid.userData.colliding) {
        const distanceSq = boid.position.distanceSq(target)
        if (distanceSq < boid.radius * boid.radius + 10) {
          boid.maxSpeed = settings.maxSpeed * 4
          boid.flee(target)
        } else {
          if (boid.maxSpeed > settings.maxSpeed) {
            boid.maxSpeed = lerp(boid.maxSpeed, settings.maxSpeed, 0.05)

            if (boid.maxSpeed - settings.maxSpeed < 0.05) {
              boid.maxSpeed = settings.maxSpeed
            }
          }
          boid.wander()

          if (distanceSq < settings.arriveMaxDistance * settings.arriveMaxDistance) {
            boid.arrive(target)
          }
        }
      }
      boid.update(dt)

      boid.userData.rotation += boid.userData.rotationSpeed

      const point = boid.position
      const { scale, rotation, img } = boid.userData

      gfx.image(img, point.x - settings.radius, point.y - settings.radius, {
        rotation,
        scale,
        alpha: transparency ? scale : 1
      })
    }
  }

  function updateRect () {
    if (!container) {
      return
    }
    // const { left, top, width, height } = containerRef.current.getBoundingClientRect()
    const { left, top, width, height } = container.getBoundingClientRect()
    const pX = window.pageXOffset
    const pY = window.pageYOffset
    rect.x = pX + left
    rect.y = pY + top
    rect.width = width
    rect.height = height
  }

  function onResize () {
    window.requestAnimationFrame(() => {
      updateRect()

      gfx.size(rect.width, rect.height)

      for (let i = 0; i < boids.length; i++) {
        boids[i].setBounds(rect.width, rect.height)
      }
    })
  }

  useEffect(() => {
    if (container) {
      updateRect()
      initialize(canvas.current, bgColor, count, radius, transparency, images)
      window.addEventListener('resize', onResize)
      coords.on()
      loop.add(onLoop)
      loop.start()
    }
    return () => {
      window.removeEventListener('resize', onResize)
      coords && coords.off()
      loop.remove(onLoop)
    }
  }, [container])

  return (
    <canvas ref={canvas} style={{ position: 'absolute', left: 0, top: 0}} />
  )
}
